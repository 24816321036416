import { getRewardPoints } from '@/api/rewardPoint/rewardPoint'
import { useTableOptions } from '@/composables'
import { reactive, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default () => {
  const loading = ref(false)

  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'User', value: 'user' },
    { text: 'Type', value: 'type' },
    { text: 'Traceable', value: 'traceable' },
    { text: 'Points', value: 'points' },
    { text: 'Expiry Date', value: 'expired_at' },
  ]

  const tableItems = ref([])
  const tableTotal = ref(0)

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )

  // Filter
  const filters = reactive({
    type: null,
    email: null,
  })

  const loadPoints = async () => {
    loading.value = true

    try {
      const {
        status,
        data: { records, pagination },
      } = await getRewardPoints(useTableOptions(options.value, parseFilters()))

      if (status === 'success') {
        tableItems.value = records
        tableTotal.value = pagination.total
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const parseFilters = () =>
    Object.keys(filters)
      .filter(key => filters[key] !== null)
      .reduce((a, key) => ({ ...a, [key]: filters[key] }), {})

  watch([options], () => {
    loadPoints()
  })

  watch(
    () => filters.type,
    () => {
      reset()
    },
    { deep: true },
  )

  watch(
    () => filters.email,
    debounce(() => {
      reset()
    }, 500),
    { deep: true },
  )

  const reset = () => {
    options.value.page = 1

    loadPoints()
  }

  return {
    loading,

    tableColumns,
    tableItems,
    tableTotal,

    options,
    filters,

    loadPoints,
  }
}
