<template>
  <div id="reward-point-list">
    <v-card class="filter-section">
      <v-row class="">
        <v-col cols="6">
          <v-card-title class="font-weight-bold "> {{$t('common.list.filters')}} </v-card-title>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <v-btn icon @click="showFilter = !showFilter">
            <v-icon>{{ showFilter ? icons.mdiMenuUp : icons.mdiMenuDown }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row class="px-2 ma-0" v-show="showFilter">
          <!-- role filter -->

          <v-col cols="12" sm="3">
            <v-text-field v-model="filters.email" single-line dense outlined hide-details clearable
              :placeholder="$t('common.list.searchUser')"></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-select v-model="filters.type" :placeholder="$t('common.list.selectType')"
              :items="pointTypes" item-text="title" item-value="value" single-line dense outlined
              clearable hide-details></v-select>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card>

    <v-card class="mt-5">
      <!-- table -->
      <v-data-table :headers="tableColumns" :items="tableItems" :options.sync="options"
        :server-items-length="tableTotal" :loading="loading" class="text-no-wrap table-section"
        :headerProps="headerprops">
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>
        <!-- User -->
        <template #[`item.user`]="{ item }">
          <router-link :to="{ name: 'user-view', params: { id: item.user.id } }">
            {{ item.user.email }}
          </router-link>
        </template>

        <!-- Traceable -->
        <template #[`item.traceable`]="{ item }">
          <span class="text-no-wrap">{{ parseTraceableData(item.type, item.traceable) }}</span>
        </template>

        <!-- expiry date -->
        <template #[`item.expired_at`]="{ item }">
          <span
            class="text-no-wrap">{{ item.expired_at ? dayjs(item.expired_at).format('YYYY-MM-DD HH:mm:ss') : 'Never'}}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiDatabaseExportOutline, mdiDeleteOutline, mdiDotsVertical, mdiMenuDown, mdiMenuUp, mdiPencilOutline, mdiRefresh } from '@mdi/js'

import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import useRewardPoint from './useRewardPoint'

export default {
  components: {},

  setup() {
    const { tableItems, tableColumns, tableTotal, options, filters, loading, loadPoints } = useRewardPoint()

    const pointTypes = ref([
      {
        title: 'Employee Benefit',
        value: 'employee_benefit',
      },

      {
        title: 'Employee Reward',
        value: 'employee_reward',
      },
      {
        title: 'Reward Campaign',
        value: 'reward_campaign',
      },
      {
        title: 'Promotion',
        value: 'promotion',
      },
      {
        title: 'Refund',
        value: 'refund',
      },
      {
        title: 'Generic',
        value: 'generic',
      },
      {
        title: 'Free Trial',
        value: 'free_trial',
      },
      {
        title: 'Product Review',
        value: 'product_review',
      },
      {
        title: 'Activity Inviation',
        value: 'activity_invitation',
      },
      {
        title: 'Product Review',
        value: 'product_review',
      },
    ])

    const parseTraceableData = (type, data) => {
      if (!data) {
        return '-'
      }

      if (type === 'voucher') {
        return data.code
      }
    }

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    return {
      dayjs,

      loading,
      t,
      formatDate,

      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiRefresh,
        mdiDatabaseExportOutline,
        mdiMenuDown,
        mdiMenuUp,
      },

      tableColumns,
      tableItems,
      tableTotal,
      options,
      filters,

      pointTypes,

      loadPoints,

      parseTraceableData,
      showFilter,
      singleSelect,
      headerprops,
    }
  },
}
</script>

<style lang="scss" scoped></style>
