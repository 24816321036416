import axios from '@axios'

const getRewardPoints = async params => {
  const { status, data } = await axios.get('/admin/reward-points', { params })

  if (status === 200) {
    return data
  }

  return null
}

export { getRewardPoints }
