var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "reward-point-list"
    }
  }, [_c('v-card', {
    staticClass: "filter-section"
  }, [_c('v-row', {}, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card-title', {
    staticClass: "font-weight-bold "
  }, [_vm._v(" " + _vm._s(_vm.$t('common.list.filters')) + " ")])], 1), _c('v-col', {
    staticClass: "d-flex justify-end",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.showFilter = !_vm.showFilter;
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.showFilter ? _vm.icons.mdiMenuUp : _vm.icons.mdiMenuDown))])], 1)], 1)], 1), _c('v-expand-transition', [_c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showFilter,
      expression: "showFilter"
    }],
    staticClass: "px-2 ma-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "single-line": "",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "clearable": "",
      "placeholder": _vm.$t('common.list.searchUser')
    },
    model: {
      value: _vm.filters.email,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "email", $$v);
      },
      expression: "filters.email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": _vm.$t('common.list.selectType'),
      "items": _vm.pointTypes,
      "item-text": "title",
      "item-value": "value",
      "single-line": "",
      "dense": "",
      "outlined": "",
      "clearable": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filters.type,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "type", $$v);
      },
      expression: "filters.type"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-5"
  }, [_c('v-data-table', {
    staticClass: "text-no-wrap table-section",
    attrs: {
      "headers": _vm.tableColumns,
      "items": _vm.tableItems,
      "options": _vm.options,
      "server-items-length": _vm.tableTotal,
      "loading": _vm.loading,
      "headerProps": _vm.headerprops
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" #" + _vm._s(item.id) + " ")];
      }
    }, {
      key: "item.user",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'user-view',
              params: {
                id: item.user.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.user.email) + " ")])];
      }
    }, {
      key: "item.traceable",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(_vm.parseTraceableData(item.type, item.traceable)))])];
      }
    }, {
      key: "item.expired_at",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(item.expired_at ? _vm.dayjs(item.expired_at).format('YYYY-MM-DD HH:mm:ss') : 'Never'))])];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }